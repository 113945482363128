export default {
  tableFields:   [
      {
          name:      '__component:badge-column',
          title:     '',
          dataClass: 'text-center',
          width:     '4%'
      },
      {
          name:      'name',
          sortField: 'name',
          width:     '24%'
      },
      {
          name:  'updated_at',
          title: 'updated_at',
          width: '24%'
      },
      {
          name:       '__slot:active',
          title:      'Estatus',
          titleClass: "center aligned",
          dataClass:  "center aligned",
          width:      "15%",
      },
  ],
  sortFunctions: {
      'name': function (item1, item2) {
          return item1 >= item2 ? 1 : -1
      },
  }
}
