<template>
    <div class="content">
        <div class="row">

            <div class="col-md-12">
                <vuestic-widget :headerText="'Dias de Evento'">
                    <p>Los dias permiten categorizar las estaciones en los distintos dias que puede tener un evento</p>
                    <div class="row">
                        <div class="col-md-12 pull-right">
                            <button type="button" v-on:click="newDayEvent" class="btn btn-primary btn-sm">Nuevo Día de evento</button>
                        </div>
                    </div>
                </vuestic-widget>
            </div>
            <div class="col-md-12">
                <TableDayEvent></TableDayEvent>

            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import TableDayEvent from '../tables/TableDayEvent/TableDayEvent.vue'
    import {SpringSpinner} from 'epic-spinners'
    export default {
        name:       'Days',
        components: {
            SpringSpinner,
            TableDayEvent
        },
        data() {
            return {}
        },
        methods: {
            newDayEvent () {
                this.$router.push("day/new")
            }

        }
    }
</script>

<style lang="scss">

    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }
</style>
